import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { Toast } from 'vant';
import Vue from 'vue';
import { Swipe, SwipeItem } from 'vant';
import { Tabbar, TabbarItem } from 'vant';
import { Grid, GridItem } from 'vant';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { List } from 'vant';
import { Slider } from 'vant';
import { Collapse, CollapseItem } from 'vant';
import 'vant/es/empty/style';
import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  watch: {
    $route(to, from) {
      this.$router.go(0);
    }
  },
  setup() {
    const activeNames = ref(['1']);
    return {
      activeNames
    };
  },
  data() {
    return {
      banner: [],
      goods: [],
      lang_list: [],
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      news: [],
      sort_line: 0,
      loading: false,
      finished: false,
      is_jia: false,
      page: 1,
      value: [0, 100000],
      shuxing: [],
      select_v: 0,
      new11: {}
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    // let sort_line =
    //   local.readInfo('sort_line1') == undefined ||
    //     local.readInfo('sort_line1') == null
    //     ? 0
    //     : local.readInfo('sort_line1');
    // this.sort_line = sort_line;
    // this.onload(true)
  },
  methods: {
    get_imdex_data: function () {
      console.log(111111);
      api.all('/api/index/mail', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.user = data.data.user;
          this.new11 = data.data.new;
        } else {
          console.log(data);
          //  if(data&&'msg' in data){
          //    Toast(data.msg)
          //  }
          //this.block = false
        }
      });
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    goto: function (name, id, tit) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: id,
          tit: tit
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    }
  }
};